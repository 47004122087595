import {MODULE_NAME} from '../../shared/const';
import {Themes} from '../../shared/typings';
import {logger} from './logger';

interface PageCriteria {
  geo?: 'US' | 'GB';
  subchannel?: string;
  darkMode?: boolean;
}

interface MetaObject {
  [key: string]: string | {[key: string]: string} | undefined | number;
}

export interface DMWindow extends Window {
  DM?: {
    getPageMetadata: () => {[key: string]: string};
    molFeTracking: {
      pageEvents: {
        TOP_STORIES_XP_CLICK: string;
        TOP_STORIES_XP_IMPRESSION: string;
        TOP_STORIES_XP_VIEW_MORE: string;
        TOP_STORIES_XP_VIEWABLE: string;
        publish: (eventKey: string, meta?: MetaObject) => void;
      };
    };
  };
  PageCriteria?: PageCriteria;
}

type EventKey =
  | 'TOP_STORIES_XP_CLICK'
  | 'TOP_STORIES_XP_IMPRESSION'
  | 'TOP_STORIES_XP_VIEW_MORE'
  | 'TOP_STORIES_XP_VIEWABLE';

const sendEventToMolFeTracking = (eventKey: EventKey, meta?: MetaObject) => {
  try {
    const win = window as DMWindow;
    const {DM} = win;

    if (DM && DM.molFeTracking) {
      const {pageEvents} = DM.molFeTracking;

      pageEvents.publish(pageEvents[eventKey], meta);
    }
  } catch (error) {
    logger.error(`[${MODULE_NAME}] [error] Unable to track events`, error);
  }
};

const MOL_FE_TRACKING_KEY_BY_EVENT: {[key: string]: EventKey} = {
  click: 'TOP_STORIES_XP_CLICK',
  impression: 'TOP_STORIES_XP_IMPRESSION',
  viewable: 'TOP_STORIES_XP_VIEWABLE',
  viewMore: 'TOP_STORIES_XP_VIEW_MORE'
};

export const trackEvent = (event: string, colorTheme?: Themes, articlePosition?: number, urlClicked?: string) => {
  const molFeTrackingKey = MOL_FE_TRACKING_KEY_BY_EVENT[event];

  if (molFeTrackingKey) {
    sendEventToMolFeTracking(molFeTrackingKey, {articlePosition, colorTheme, urlClicked});
  }
};

export const getPageCriteria = () => {
  const pageCriteria = (window as DMWindow).PageCriteria;

  return pageCriteria || ({} as PageCriteria);
};
