import React, {SyntheticEvent} from 'react';
import classnames from 'classnames';
import {Platforms} from '@mol-fe/mol-fe-registry-types';
import {ArticleType, EnrichedItem, Themes} from '../shared/typings';
import styles from './styles.css';
import {Breakpoint, Layout} from './typings';
import {LinkAttrValues} from './App';

type RenderersProps = {
  items: EnrichedItem[];
  breakpoint?: Breakpoint;
  onImageErrorSetDefault: (event: SyntheticEvent<HTMLImageElement>) => void;
  layoutType?: Layout;
  heroThumbnails?: EnrichedItem[];
  targetAttrValue: LinkAttrValues;
  colorTheme: Themes;
  platform?: Platforms;
};

const createMarkup = (content: string) => {
  return {__html: content};
};

const ArticleTypeBadge = ({type}: {type: ArticleType}) => {
  if (!type) {
    return null;
  }

  switch (type) {
    case 'live':
      return (
        <span className={classnames(styles.articleType, styles.articleTypeLive)}>
          <span className={styles.articleTypeLiveWrapper}>
            <span className={styles.articleTypeLiveIcon} />
            <span className="mol-bullet-icon">{type}</span>
          </span>
        </span>
      );
    case 'sponsored':
      return <span className={classnames(styles.articleType, styles.articleTypeSponsored)}>{type}</span>;
    case 'breaking':
    case 'exclusive':
      return <span className={classnames(styles.articleType, styles.articleTypeBadge)}>{type}</span>;
    default:
      return null;
  }
};

const ThumbnailRenderer = ({
  items,
  heroThumbnails = [],
  onImageErrorSetDefault,
  targetAttrValue,
  breakpoint
}: Omit<RenderersProps, 'colorTheme'>) => {
  const itemsCopy = items.slice();
  const ARTICLES_PER_ROW = items.length >= 6 && heroThumbnails?.length > 1 ? 6 : 3;
  const itemsToRender = [];
  const isMultiRow = breakpoint && ['large', 'extra-large'].includes(breakpoint);
  const rows = isMultiRow ? Math.floor(itemsCopy.length / ARTICLES_PER_ROW) : 1;

  if (rows <= 1) {
    itemsToRender.push(itemsCopy);
  } else {
    for (let inc = rows; inc > 0; inc--) {
      itemsToRender.push(itemsCopy.splice(0, ARTICLES_PER_ROW));
    }
  }

  return (
    <>
      {itemsToRender.map((item) => {
        return (
          <div className={classnames(styles.groupedArticles, rows === 2 && styles.doubleColumn)} key={Math.random()}>
            {item.map(({url, articleType, imageUrl, heading, isPaywalled}) => {
              return (
                <div key={url} className={styles.article}>
                  <a href={url} target={targetAttrValue} className={styles.articleChild}>
                    <img
                      className={styles.articleChildImg}
                      src={imageUrl}
                      alt=""
                      loading="lazy"
                      onError={onImageErrorSetDefault}
                    />

                    <h2 className={styles.articleChildText}>
                      <div className={styles.articleBadgeContainer}>
                        {isPaywalled && (
                          <span
                            className={classnames('is-paywalled', {
                              'is-paywalled-sponsored': articleType === 'sponsored'
                            })}
                          />
                        )}
                        <ArticleTypeBadge type={articleType} />
                      </div>
                      <span dangerouslySetInnerHTML={createMarkup(heading)} />
                    </h2>
                  </a>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
const HeroThumbnailRenderer = ({
  items,
  colorTheme,
  breakpoint,
  targetAttrValue,
  onImageErrorSetDefault
}: RenderersProps) => {
  return (
    <>
      {items.map(({subheading, url, hideSubheading, imageUrl, heading, articleType, isPaywalled}) => {
        const renderSubheading = colorTheme !== 'royals' && breakpoint !== 'medium' && subheading && !hideSubheading;
        const headerTextClamp = renderSubheading ? styles.articleHeadlineClamp4 : styles.articleHeadlineClamp5;

        return (
          <div key={url} className={classnames(styles.article, styles.articleFirstChild)}>
            <a href={url} target={targetAttrValue}>
              <img src={imageUrl} alt="" loading="lazy" onError={onImageErrorSetDefault} />
              <div className={styles.articleTopContainer}>
                <h2 className={classnames(styles.articleHeadline, headerTextClamp)}>
                  <div className={styles.articleBadgeContainer}>
                    {isPaywalled && (
                      <span
                        className={classnames('is-paywalled', {
                          'is-paywalled-sponsored': articleType === 'sponsored'
                        })}
                      />
                    )}
                    <ArticleTypeBadge type={articleType} />
                  </div>
                  <span dangerouslySetInnerHTML={createMarkup(heading)} />
                </h2>
                {renderSubheading && (
                  <p className={styles.articleSubheading} dangerouslySetInnerHTML={createMarkup(subheading)} />
                )}
              </div>
            </a>
          </div>
        );
      })}
    </>
  );
};
const WithPreviewRenderer = ({
  items,
  breakpoint,
  onImageErrorSetDefault,
  targetAttrValue,
  colorTheme,
  platform
}: RenderersProps) => {
  if (breakpoint === 'medium') {
    return (
      <ThumbnailRenderer
        targetAttrValue={targetAttrValue}
        items={items}
        onImageErrorSetDefault={onImageErrorSetDefault}
        breakpoint={breakpoint}
      />
    );
  }

  let heroThumbnailsCount = items.length >= 8 ? 2 : 1;

  if (
    colorTheme === 'sportAU' &&
    platform === 'mol.web.mobile' &&
    ['extra-small', 'small', 'medium'].includes(breakpoint || 'small')
  ) {
    heroThumbnailsCount = 1;
  }

  const heroThumbnails = items.splice(0, heroThumbnailsCount);

  return (
    <div className={styles.previewRendererWrapper} data-no-hero-thumbnails={heroThumbnails.length}>
      <div>
        <HeroThumbnailRenderer
          items={heroThumbnails}
          colorTheme={colorTheme}
          breakpoint={breakpoint}
          targetAttrValue={targetAttrValue}
          onImageErrorSetDefault={onImageErrorSetDefault}
        />
      </div>
      <ThumbnailRenderer
        targetAttrValue={targetAttrValue}
        items={items}
        onImageErrorSetDefault={onImageErrorSetDefault}
        heroThumbnails={heroThumbnails}
        breakpoint={breakpoint}
      />
    </div>
  );
};

const LayoutRenderer = ({
  layoutType,
  items,
  breakpoint,
  onImageErrorSetDefault,
  targetAttrValue,
  colorTheme,
  platform
}: RenderersProps) => (
  <>
    {layoutType === 'with-preview' ? (
      <WithPreviewRenderer
        colorTheme={colorTheme}
        items={items}
        breakpoint={breakpoint}
        onImageErrorSetDefault={onImageErrorSetDefault}
        targetAttrValue={targetAttrValue}
        platform={platform}
      />
    ) : (
      <ThumbnailRenderer
        items={items}
        onImageErrorSetDefault={onImageErrorSetDefault}
        targetAttrValue={targetAttrValue}
        breakpoint={breakpoint}
      />
    )}
  </>
);

export default LayoutRenderer;
