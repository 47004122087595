const sendAmpResize = () => {
  window.parent.postMessage(
    {
      height: document.body.scrollHeight,
      sentinel: 'amp',
      type: 'embed-size'
    },
    '*'
  );
};

export const requestResize = () => {
  try {
    if (window.parent === window) {
      return;
    }

    if (window.location.search.match(/renderer=amp/)) {
      setTimeout(sendAmpResize, 500);
    }
  } catch (error) {
    // Ignore error
  }
};
