import {GEO} from '../../shared/typings';

interface DMWindow {
  PageCriteria?: {
    geo?: string;
  };
  getIsPaywallEligible?: () => boolean;
}

const PAYWALL_EXCLUDE_GEOS = [
  'US',
  'CA',
  'AU',
  'NZ',
  'IN',
  'IR',
  'CU',
  'KP',
  'SY',
  'VE',
  'AF',
  'BY',
  'BI',
  'CF',
  'CN',
  'HK',
  'CD',
  'EG',
  'ER',
  'ET',
  'IQ',
  'LB',
  'LY',
  'ML',
  'MM',
  'NI',
  'GN',
  'GW',
  'RU',
  'SO',
  'SS',
  'SD',
  'TN',
  'TR',
  'UA',
  'YE',
  'ZW'
];

export const getIsPaywallEligible = (moduleGeo: GEO) => {
  const win = window as DMWindow;

  if (win.PageCriteria?.geo && win.getIsPaywallEligible) {
    return win.getIsPaywallEligible();
  }

  return !PAYWALL_EXCLUDE_GEOS.includes(moduleGeo.toUpperCase());
};
