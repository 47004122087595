import React from 'react';
import {render} from 'react-dom';
import {SizeMe} from 'react-sizeme';
import {MODULE_NAME} from '../shared/const';
import App from './App';
import {handleEventTracker} from './utils/handleEvents';
import {requestResize} from './utils/helpers';
import {AppData, Breakpoint} from './typings';
import {logger} from './utils/logger';

const SMALL_WIDTH = 310;
const MEDIUM_WIDTH = 472;
const LARGE_WIDTH = 600;
const EXTRA_LARGE_WIDTH = 900;

const getBreakpoint = (width: number | null): Breakpoint => {
  if (!width) {
    return 'extra-small';
  }

  if (width >= EXTRA_LARGE_WIDTH) {
    return 'extra-large';
  }

  if (width >= LARGE_WIDTH) {
    return 'large';
  }

  if (width >= MEDIUM_WIDTH) {
    return 'medium';
  }

  if (width > SMALL_WIDTH) {
    return 'small';
  }

  return 'extra-small';
};

const createApp = async () => {
  Array.from(document.querySelectorAll('[data-mol-fe-xpmodule-top-stories]')).forEach(async (element) => {
    const divElem = element as HTMLDivElement;
    const dataToParse = divElem.dataset.content || '';

    if (dataToParse) {
      try {
        const data: AppData = JSON.parse(dataToParse);

        render(<SizeMe>{({size: {width}}) => <App {...data} breakpoint={getBreakpoint(width)} />}</SizeMe>, divElem);

        delete divElem.dataset.content;
        handleEventTracker(divElem, data.colorTheme);
        window.addEventListener('load', requestResize, false);
        window.addEventListener('resize', requestResize, false);
        setInterval(requestResize, 1500);
      } catch (error) {
        logger.error(`[${MODULE_NAME}] [error] Unable to parse data and to render the module`, error);
      }
    } else {
      logger.error(`[${MODULE_NAME}] [error] Missing content data`);
    }
  });
};

const init = () => {
  if ((document.readyState === 'interactive' && Boolean(document.body)) || document.readyState === 'complete') {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createApp().catch();
  } else {
    document.addEventListener('DOMContentLoaded', createApp);
  }
};

init();
