import {Themes} from '../../shared/typings';
import {MODULE_NAME} from '../../shared/const';
import {logger} from './logger';
import {trackEvent} from './tracking';

export const handleEventTracker = (element: HTMLDivElement, colorTheme?: Themes) => {
  const articles = element.querySelectorAll('[data-xp-top-stories]');

  try {
    // impression event
    const observer = new IntersectionObserver(async (entries, obs) => {
      const entry = entries[0];

      if (entry.isIntersecting) {
        trackEvent('viewable', colorTheme);
        obs.disconnect();
      }
    });

    observer.observe(element);

    // click event on each article
    articles.forEach((el, idx) => {
      const link = el.querySelector('a');
      const href = (link && (link as HTMLAnchorElement).href) || undefined;

      el.addEventListener('click', async () => {
        trackEvent('click', colorTheme, idx, href);
      });
    });

    trackEvent('impression', colorTheme);

    const viewMoreButton = element.querySelector('.xp-top-stories-button');

    if (viewMoreButton) {
      viewMoreButton.addEventListener('click', () => {
        trackEvent('viewMore', colorTheme);
      });
    }
  } catch (error) {
    logger.error(`[${MODULE_NAME}] [error] Unable to track events`, error);
  }
};
