import {useEffect, useState} from 'react';
import {logger} from '../utils/logger';
import {MODULE_NAME} from '../../shared/const';

const imageLoader = async (path: string) => import(`../assets/${path}`);

const useImageLoader = (imgPath: string) => {
  const [imgSrc, setImgSrc] = useState(undefined);
  let hasImageLoaded = false;

  useEffect(() => {
    if (!hasImageLoaded) {
      imageLoader(imgPath)
        .then((resp) => setImgSrc(resp.default))
        .catch((error) => {
          logger.error(`[${MODULE_NAME}] [error] Unable to load image`, error);
        });
    }

    return () => {
      hasImageLoaded = true;
    };
  }, [imgPath]);

  return {
    imgSrc
  };
};

export default useImageLoader;
